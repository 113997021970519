/* eslint-disable */
import React, { Suspense } from "react";
import CacheBuster from "react-cache-buster";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { Wrapper } from "@googlemaps/react-wrapper";
import { LicenseManager } from "ag-grid-enterprise";
import "ag-grid-enterprise";
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
import { MockAPIProvider, mockApiContextDefaultValues } from "helpers/contexts/MockAPIContext";
import { theme } from "helpers/theme";
import "primeflex/primeflex.css";

/* global Office */
// must be imported in this order!
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "@fortawesome/fontawesome-pro/css/all.min.css";
import { ThemeProvider } from "styled-components";

import "layout/sass/fundmanager.scss";
import Loading from "pages/Shared/Loading";
import { store } from "services/localStore/store";

import * as serviceWorker from "./serviceWorker";
import packageInfo from "../package.json";

/* eslint-enable */

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY);

// Static imports
// import AppWrapper from "./AppWrapper";
// import AppAddin from "./AppAddin";

// Dynamic imports
const AppWrapper = React.lazy(() => import("./AppWrapper"));
const AppAddin = React.lazy(() => import("./AppAddin"));

const render = (status) => {
    return <div>{status}</div>;
};

const isProduction = process.env.REACT_APP_ENV !== "local";

const container = document.getElementById("root");
const root = createRoot(container);
const renderApp = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    root.render(
        <CacheBuster
            currentVersion={packageInfo.version}
            isEnabled={isProduction}
            isVerboseMode={false}
            loadingComponent={<Loading />}
            metaFileDirectory={"."}
        >
            <Suspense fallback={<Loading />}>
                <Provider store={store}>
                    <MockAPIProvider value={mockApiContextDefaultValues}>
                        <ThemeProvider theme={theme}>
                            <Router>
                                <Wrapper
                                    apiKey={process.env.REACT_APP_GOOGLE_AUTOCOMPLETE_KEY}
                                    render={render}
                                    libraries={["places"]}
                                >
                                    <AppWrapper />
                                </Wrapper>
                            </Router>
                        </ThemeProvider>
                    </MockAPIProvider>
                </Provider>
            </Suspense>
        </CacheBuster>
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister();
};

const renderAppAddin = () => {
    root.render(
        <Suspense fallback={<Loading showUserAgentInfo={true} />}>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <Router>
                        <Wrapper
                            apiKey={process.env.REACT_APP_GOOGLE_AUTOCOMPLETE_KEY}
                            render={render}
                            libraries={["places"]}
                        >
                            <AppAddin />
                        </Wrapper>
                    </Router>
                </ThemeProvider>
            </Provider>
        </Suspense>
    );
};

/* Render application after Office initializes */
Office.onReady(function (info) {
    if (info.host !== "Word") renderApp();
    else renderAppAddin();
});
