import * as OfficeHelpers from "@microsoft/office-js-helpers";

export default class AuthenticatorOffice {
    constructor() {
        console.log("authenticatorOffice ctor");
        this.authenticator = new OfficeHelpers.Authenticator();

        this.authenticator.endpoints.registerAzureADAuth(
            process.env.REACT_APP_AUTH_CLIENT_ID,
            process.env.REACT_APP_AUTH_TENANTID,
            {
                baseUrl: process.env.REACT_APP_AUTH_AUTHORITY,
                scope: "openid profile usercontext email",
                resource: process.env.REACT_APP_AUTH_RESOURCE,
                redirectUrl: process.env.REACT_APP_AUTH_REDIRECT_URI.replace("[origin]", window.location.origin),
            }
        );
    }

    getUserAsync() {
        return new Promise((resolve) => {
            const token = this.authenticator.tokens.get(OfficeHelpers.DefaultEndpoints.AzureAD);
            resolve(AuthenticatorOffice.createUserObject(token));
        });
    }

    // Login Action.
    loginAsync() {
        return new Promise((resolve, reject) => {
            this.authenticator
                .authenticate(OfficeHelpers.DefaultEndpoints.AzureAD)
                .then((token) => {
                    console.log("authenticatorOffice.loginAsync", token !== undefined && token !== null);
                    resolve(AuthenticatorOffice.createUserObject(token));
                })
                .catch((error) => {
                    console.log("authenticatorOffice.loginAsync error", error);
                    reject(error);
                });
        });
    }

    // Call this on the landing redirect page.
    loginCallbackAsync() {
        return new Promise((resolve) => {
            const token = this.authenticator.tokens.get(OfficeHelpers.DefaultEndpoints.AzureAD);
            console.log("authenticatorOffice.loginCallbackAsync", token !== undefined && token !== null);
            resolve(AuthenticatorOffice.createUserObject(token));
        });
    }

    /* Private metods */
    static createUserObject(token) {
        if (token && !token.profile) {
            token.profile = { unique_name: "?" };
        }
        return token;
    }
}
