const defaultFundtSettings = {
    selectedFunds: [],
    selectedCountries: [],
};

export default (state = defaultFundtSettings, action) => {
    switch (action.type) {
        case "SET_FUND_SETTINGS":
            return {
                ...state,
                selectedFunds: action.value,
            };
        case "SET_COUNTRY_SETTINGS":
            return {
                ...state,
                selectedCountries: action.value,
            };
        case "SET_FUNDS":
            return {
                ...state,
                funds: action.value,
            };
        default:
            return state;
    }
};
