import * as Oidc from "oidc-client";

import AuthenticatorOidcSettings from "./authenticator.oidc.settings";

export default class AuthenticatorOidc {
    constructor() {
        // console.log("authenticatorOidc ctor");
        this.userManager = new Oidc.UserManager(AuthenticatorOidcSettings);
    }

    subscribeEvents(accessTokenExpiringHandler) {
        /* These are the events available:
        _accessTokenExpiringNotificationTime, _accessTokenExpiring, _accessTokenExpired 
        _userLoaded, _userUnloaded, _silentRenewError, _userSignedOut, _userSessionChanged 
        for (const key in this.userManager.events) {
            if (this.userManager.events.hasOwnProperty(key)) {
                let value = this.userManager.events[key];
                console.log(key, value);
            } else {
                //property from protytpe chain
            }
        }
        */

        // if autorefresh is on there is already a callback registered and there's no need to do this
        // console.log(this.userManager.events._accessTokenExpiring._callbacks[0]);
        if (this.userManager.events._accessTokenExpiring._callbacks.length === 0) {
            console.log("Subscribed to accessTokenExpiring");
            this.userManager.events.addAccessTokenExpiring(accessTokenExpiringHandler);
        } else {
            console.log("accessTokenExpiring is already registered");
        }
    }

    getUserAsync() {
        return this.userManager.getUser();
    }

    // Login Action.
    loginAsync() {
        return this.userManager.signinRedirect();
    }

    // Call this on the landing redirect page.
    loginCallbackAsync() {
        return this.userManager.signinRedirectCallback();
    }

    // Logout Action.
    logoutAsync() {
        return this.userManager.signoutRedirect();
    }

    // Call this on logout completion.
    logoutCallback() {
        this.userManager.removeUser();
        this.userManager.clearStaleState();
    }

    // Ask for an explicit silent signin.
    async loginSilentAsync() {
        const user = await this.getUserAsync();
        console.log("authenticator >> oidc.loginSilentAsync", user.profile.unique_name);
        return this.userManager.signinSilent({ extraQueryParams: { login_hint: user.profile.unique_name } });
    }

    // Call this on the landing redirect page.
    loginSilentCallbackAsync() {
        console.log("authenticator >> oidc.loginSilentCallbackAsync");
        return this.userManager.signinSilentCallback();
    }
}
