const defaultevents = null;

export default (state = defaultevents, action) => {
    switch (action.type) {
        case "SHOW":
            return { ...action.message, showTask: state?.showTask };
        case "HIDE":
            return null;
        case "SHOWTASK":
            return { ...state, showTask: true, taskId: action.message.taskId };
        case "HIDETASK":
            return { ...state, showTask: false };
        default:
            return state;
    }
};
