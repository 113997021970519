const tempProfile = {};

const removeProfileById = (profile, gridId) => {
    const newProfile = { ...profile };
    delete newProfile[gridId];
    return newProfile;
};

export default (state = tempProfile, action) => {
    switch (action.type) {
        case "REMOVE_PROFILE":
            return removeProfileById([tempProfile], action.gridId);
        case "SAVE_PROFILE":
            return {
                ...state,
                [action.gridId]: {
                    ...state[action.gridId],
                    columnState: action.columnState,
                    filterState: action.filterState,
                },
            };
        case "SAVE_QCK_FILTER":
            return {
                ...state,
                [action.gridId]: {
                    ...state[action.gridId],
                    quickFilterState: action.quickFilterValue || "",
                },
            };
        default:
            return state;
    }
};
