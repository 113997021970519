import React, { useEffect, useRef } from "react";

import i18next from "i18next";
import { Toast } from "primereact/toast";

import { show, showMultiple } from "services/localStore/actions/appMessage";
import { store } from "services/localStore/store";

import styles from "./AppMessage.module.scss";

/** Temporarily AppMessage (prime react Growl) */
export function AppMessage(props) {
    const toast = useRef(null);

    const message = props.message;

    useEffect(() => {
        if (Array.isArray(message)) {
            toast.current.show(message);
        } else if (message) toast.current.show({ ...message, sticky: message.severity === "error", life: 3000 });
    }, [message]);

    if (message && !message.summary && !Array.isArray(message)) {
        switch (message.severity) {
            case "success":
                message.summary = i18next.t("common:msg.success.title");
                break;
            case "info":
                message.summary = i18next.t("common:msg.information.title");
                break;
            case "warn":
                message.summary = i18next.t("common:msg.warning.title");
                break;
            case "error":
                message.summary = i18next.t("common:msg.error.title");
                break;
            default:
                return;
        }
    }
    return (
        <React.Fragment>
            <Toast className={styles["gx-app-message"]} ref={toast} />
        </React.Fragment>
    );
}

/** Fires a store action so that the right component with the global AppMessage component renders the message */
export function showMessage(message, summary = null) {
    return store.dispatch(show(message, "success", summary));
}
/** Fires a store action so that the right component with the global AppMessage component renders the message */
export function showSuccessMessage(message, summary = null) {
    return store.dispatch(show(message, "success", summary));
}
/** Fires a store action so that the right component with the global AppMessage component renders the message */
export function showInfoMessage(message, summary = null) {
    return store.dispatch(show(message, "info", summary));
}
/** Fires a store action so that the right component with the global AppMessage component renders the message */
export function showWarningMessage(message, summary = null) {
    return store.dispatch(show(message, "warn", summary));
}
/** Fires a store action so that the right component with the global AppMessage component renders the message */
export function showErrorMessage(message, summary = null) {
    return store.dispatch(show(message, "error", summary));
}

export function showMultipleMessage(message) {
    return store.dispatch(showMultiple(message));
}

export default AppMessage;
