import { createContext, useEffect } from "react";
import React from "react";

import { IServer, makeServer } from "helpers/mocks/server";

export interface IMockAPIContextProps {
    setMockApi: React.Dispatch<
        React.SetStateAction<{
            status: boolean;
            rest?: IServer["rest"];
        }>
    >;
    mockApi: {
        status: boolean;
        rest?: IServer["rest"];
    };
}

export const mockApiContextDefaultValues: IMockAPIContextProps = {
    mockApi: {
        status: false,
        rest: {
            models: {},
            seeds: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
            routesList: [
                {
                    path: "",
                    controller: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
                },
            ],
        },
    },
    setMockApi: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
};

export const MockAPIContext = createContext<IMockAPIContextProps>(mockApiContextDefaultValues);

interface IMockAPIProvider {
    children: React.ReactNode;
    value: IMockAPIContextProps;
}

export const MockAPIProvider = (props: IMockAPIProvider) => {
    const { children, value } = props;
    const [mockApi, setMockApi] = React.useState<{
        status: boolean;
        rest?: IServer["rest"];
    }>(value.mockApi);

    useEffect(() => {
        if (value.mockApi.status) {
            makeServer({ rest: value.mockApi.rest });
        } else if (mockApi.status) {
            makeServer({ rest: mockApi.rest });
        }
    }, [value.mockApi.status, value.mockApi?.rest, mockApi.status, mockApi?.rest]);

    return (
        <MockAPIContext.Provider
            value={{
                mockApi,
                setMockApi,
            }}
        >
            {children}
        </MockAPIContext.Provider>
    );
};
