import { colors as c } from "./colors";

export const variants = {
    text: {
        title: {
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: c.text,
        },
        subtitle: {
            fontSize: "15px",
            fontWeight: "bold",
            color: c.text,
        },
        primary: {
            fontSize: "1rem",
            fontWeight: "normal",
            color: c.primary,
        },
        caption: {
            fontSize: "0.75rem",
            fontWeight: "normal",
            color: c.primary,
        },
        link: {
            fontSize: "1rem",
            fontWeight: "normal",
            color: c.text,
            textDecoration: "none",
            cursor: "pointer",
            "&:hover": {
                opacity: "0.75",
            },
        },
    },
    button: {
        primary: {
            color: c.white,
            backgroundColor: c.primary,
            "&:hover": {
                opacity: "0.75",
            },
        },
        secondary: {
            color: c.white,
            backgroundColor: c.secondary,
            "&:hover": {
                opacity: "0.75",
            },
        },
        ghost: {
            color: c.text,
            backgroundColor: "transparent",
            "&:hover": {
                color: c.primary,
            },
        },
        danger: {
            color: c.white,
            backgroundColor: c.danger,
            "&:hover": {
                opacity: "0.75",
            },
        },
        success: {
            color: c.white,
            backgroundColor: c.success,
            "&:hover": {
                opacity: "0.75",
            },
        },
        disabled: {
            color: c.white,
            backgroundColor: c.disabled,
            cursor: "not-allowed",
        },
        option: {
            color: c.text,
            backgroundColor: c.white,
            borderRadius: "0",
            justifyContent: "flex-start",
            "&:hover": {
                backgroundColor: c.secondary,
                color: c.black,
            },
        },
    },
    select: {
        info: {
            borderColor: c.disabled,
            color: c.primary,
        },
        inline: {
            borderWidth: "0px",
            color: c.text,
            "&:focus": {
                borderWidth: "0px",
            },
        },
    },
    input: {
        autocomplete: {
            color: c.text,
            backgroundColor: c.white,
            borderColor: c.secondary,
            cursor: "pointer",
            "&:focus": {
                borderColor: c.primary,
                borderWidth: "2px",
            },
        },
        info: {
            borderColor: c.disabled,
            color: c.primary,
            cursor: "not-allowed",
        },
        checkbox: {
            color: c.primary,
            backgroundColor: c.white,
            height: "25px",
            width: "25px",
            cursor: "pointer",
        },
    },
};
