import { system, variant } from "styled-system";

import { variants } from "./variants";

enum ECustomProps {
    m = "m",
    mx = "mx",
    my = "my",
    p = "p",
    px = "px",
    py = "py",
    w = "w",
    h = "h",
    minW = "minW",
    minH = "minH",
    maxW = "maxW",
    maxH = "maxH",
    size = "size",
    align = "align",
    justify = "justify",
    direction = "direction",
    wrap = "wrap",
    d = "d",
    pos = "pos",
}

export type TCustomStyledProps = {
    [key in ECustomProps as string]: string;
};

export type ICustomStyledProps = {
    [key in ECustomProps as string]: {
        property?: string;
        scale?: string;
        properties?: string[];
    };
};

const spaceCustomProps: ICustomStyledProps = {
    m: {
        property: "margin",
        scale: "space",
    },
    mx: {
        properties: ["marginLeft", "marginRight"],
        scale: "space",
    },
    my: {
        properties: ["marginTop", "marginBottom"],
        scale: "space",
    },
    p: {
        property: "padding",
        scale: "space",
    },
    px: {
        properties: ["paddingLeft", "paddingRight"],
        scale: "space",
    },
    py: {
        properties: ["paddingTop", "paddingBottom"],
        scale: "space",
    },
};

const layoutCustomProps: ICustomStyledProps = {
    w: {
        property: "width",
        scale: "sizes",
    },
    h: {
        property: "height",
        scale: "sizes",
    },
    minW: {
        property: "minWidth",
        scale: "sizes",
    },
    minH: {
        property: "minHeight",
        scale: "sizes",
    },
    maxW: {
        property: "maxWidth",
        scale: "sizes",
    },
    maxH: {
        property: "maxHeight",
        scale: "sizes",
    },
    size: {
        properties: ["width", "height"],
        scale: "sizes",
    },
};

const flexboxCustomProps: ICustomStyledProps = {
    align: {
        property: "alignItems",
    },
    justify: {
        property: "justifyContent",
    },
    direction: {
        property: "flexDirection",
    },
    wrap: {
        property: "flexWrap",
    },
};

export const customStylesSystemProps = system({
    ...spaceCustomProps,
    ...layoutCustomProps,
    ...flexboxCustomProps,
    d: {
        property: "display",
    },
    pos: {
        property: "position",
    },
});

export const customStyleSystemVariants = {
    text: variant({
        scale: "text",
        ...variants.text,
    }),
    button: variant({
        scale: "button",
        ...variants.button,
    }),
};
