const defaultUserFavourites = [];

export default (state = defaultUserFavourites, action) => {
    switch (action.type) {
        case "SET_FAVOURITES":
            return action.favourites;
        case "ADD_FAVOURITE":
            return [...state, action.favourite];
        case "DELETE_FAVOURITE":
            return state.filter((o) => o.id !== action.id);
        default:
            return state;
    }
};
