const defaultUserContext = {
    userRoles: [],
};

interface IUserContext {
    userRoles: number[];
}

export const enum UserContextActions {
    SET_USER_CONTEXT = "SET_USER_CONTEXT",
    RESET_USER_CONTEXT = "RESET_USER_CONTEXT",
    SET_USER_ROLES = "SET_USER_ROLES",
    RESET_USER_ROLES = "RESET_USER_ROLES",
}

export default (state = defaultUserContext, action): IUserContext => {
    switch (action.type) {
        case UserContextActions.SET_USER_ROLES:
            return { ...state, userRoles: action.value };
        case UserContextActions.RESET_USER_ROLES:
            return { ...state, userRoles: defaultUserContext.userRoles };
        default:
            return state;
    }
};
