import AppFunctions from "AppFunctions";
import classNames from "classnames";

const defaultAppSettings = {
    layoutMode: AppFunctions.isDesktop() ? "static" : "overlay",
    overlayMenuActive: false,
    staticMenuDesktopInactive: false,
    staticMenuMobileActive: false,
    topbarActiveItem: null,
    topbarActiveItemId: null,
    showFooter: false,
    pinnedMenu: true,
    currentUrl: undefined,
    breadcrumbCache: [],
    lockScreen: false,
    configurations: [],
};

export default (state = defaultAppSettings, action) => {
    switch (action.type) {
        case "TOGGLE_MENU": {
            const menuState = getMenuState(state, true);
            return { ...state, ...menuState, layoutClassName: getClassName(menuState) };
        }
        case "TOGGLE_PIN": {
            const pinnedMenuState = getMenuState(state, false, !state.pinnedMenu);
            return {
                ...state,
                ...pinnedMenuState,
                pinnedMenu: !state.pinnedMenu,
                layoutClassName: getClassName(pinnedMenuState),
            };
        }
        case "HIDE_MENU": {
            const hideMenuState = getMenuState({ ...state, layoutMode: "hide" });
            return { ...state, ...hideMenuState, layoutClassName: getClassName(hideMenuState) };
        }
        case "TOPBAR_ACTIVE_ITEM":
            return { ...state, topbarActiveItem: action.value };
        case "TOPBAR_ACTIVE_ITEM_ID":
            return { ...state, topbarActiveItemId: action.value };
        case "SET_THEME":
            return { ...state, theme: action.value };
        case "SET_CONFIGURATIONS":
            return { ...state, configurations: action.value };
        case "SET_URL":
            return { ...state, currentUrl: action.value };
        case "SET_BREADCRUMBCACHE":
            return { ...state, breadcrumbCache: action.value };
        case "ADD_BREADCRUMBCACHE":
            return { ...state, breadcrumbCache: [...state.breadcrumbCache, action.value] };
        case "SET_LOCK_SCREEN":
            return { ...state, lockScreen: action.value };
        default:
            return { ...state, layoutClassName: getClassName(state) };
    }
};

const getClassName = (state) => {
    return classNames("layout-wrapper", {
        "layout-overlay": state.layoutMode === "overlay",
        "layout-static": state.layoutMode === "static",
        "layout-slim": state.layoutMode === "slim",
        "layout-static-inactive": state.staticMenuDesktopInactive,
        "layout-mobile-active": state.staticMenuMobileActive,
        "layout-overlay-active": state.overlayMenuActive,
    });
};

const getMenuState = (state, toggle = false, pinned = state.pinnedMenu) => {
    let layoutMode = state.layoutMode;
    let staticMenuDesktopInactive = state.staticMenuDesktopInactive;
    let staticMenuMobileActive = state.staticMenuMobileActive;
    let overlayMenuActive = state.overlayMenuActive;

    if (AppFunctions.isDesktop()) {
        if (toggle) {
            if (pinned) {
                layoutMode = layoutMode === "static" ? "slim" : "static";
                staticMenuDesktopInactive = false;
                staticMenuMobileActive = false;
                overlayMenuActive = false;
            } else {
                staticMenuDesktopInactive = !staticMenuDesktopInactive;
                staticMenuMobileActive = !staticMenuMobileActive;
                overlayMenuActive = !overlayMenuActive;
            }
        } else {
            if (pinned) {
                layoutMode = "static";
                staticMenuDesktopInactive = false;
                staticMenuMobileActive = false;
                overlayMenuActive = false;
            } else {
                layoutMode = "overlay";
                staticMenuDesktopInactive = true;
                staticMenuMobileActive = false;
                overlayMenuActive = true;
            }
        }
    } else {
        if (layoutMode !== "overlay") {
            layoutMode = "overlay";
            staticMenuDesktopInactive = true;
            staticMenuMobileActive = false;
            overlayMenuActive = false;
        } else {
            staticMenuDesktopInactive = !staticMenuDesktopInactive;
            staticMenuMobileActive = !staticMenuMobileActive;
            overlayMenuActive = !overlayMenuActive;
        }
    }

    return {
        layoutMode: layoutMode,
        staticMenuDesktopInactive: staticMenuDesktopInactive,
        staticMenuMobileActive: staticMenuMobileActive,
        overlayMenuActive: overlayMenuActive,
        pinnedMenu: pinned,
        theme: state.theme,
        appConfigurations: state.configurations,
    };
};
