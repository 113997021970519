/* eslint-disable */
import { createServer } from "miragejs";

// eslint enable

export interface IServer {
    rest?: {
        models: any;
        seeds(server: any): void;
        routesList: {
            path: string;
            controller: any;
            verb?: "get" | "post";
        }[];
    };
}

export function makeServer(params: IServer) {
    const { routesList, ...rest } = params.rest || {};
    const server = createServer({
        ...rest,
        routes() {
            // Basic config
            this.namespace = "api";
            this.timing = 1000;
            this.urlPrefix = "http://localhost:8080/";
            this.passthrough("http://localhost:3000/api/**");
            this.passthrough("https://localhost:44300/**");
            this.passthrough();

            // routes
            routesList?.forEach((route) => {
                if (route.verb === "post") {
                    this.post(route.path, route.controller);
                    return;
                }
                this.get(route.path, route.controller);
            });
        },
    });
    return server;
}
