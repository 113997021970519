import { apiroutes } from "services/apiroutes/apiroutes";

import { formatUtcDate } from "./format";

// Check https://www.i18next.com/overview/configuration-options
export const i18nextOptions = {
    debug: false,
    ns: [
        "assets",
        "common",
        "contactbook",
        "core",
        "security",
        "exceptions",
        "communication",
        "documentation",
        "portfolio",
        "debt",
        "finance",
        "reporting",
        "legal",
        "requests",
        "agreements",
        "duediligencies",
        "deals",
        "banking",
        "reports",
    ],
    defaultNS: "common",
    whitelist: ["en-GB", "pt-PT"],
    fallbackLng: "en-GB",
    load: "currentOnly",

    react: {
        wait: true,
    },

    interpolation: {
        escapeValue: false, // React already does escaping
        format: (value) => {
            if (value instanceof Date) {
                return formatUtcDate(value);
            }
            return value;
        },
    },

    backend: {
        // path where resources get loaded from, or a function
        // returning a path:
        // function(lngs, namespaces) { return customPath; }
        // the returned path will interpolate lng, ns if provided like giving a static path
        loadPath: apiroutes.core + "locales/{{lng}}/{{ns}}",

        // path to post missing resources
        addPath: apiroutes.core + "locales/add/{{lng}}/{{ns}}",

        // your backend server supports multiloading
        // /locales/resources.json?lng=de+en&ns=ns1+ns2
        allowMultiLoading: false, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading

        // parse data after it has been fetched
        // in example use https://www.npmjs.com/package/json5
        // here it removes the letter a from the json (bad idea)
        //parse: function(data) { return data.replace(/a/g, ''); },

        // allow cross domain requests
        crossDomain: false,

        // allow credentials on cross domain requests
        withCredentials: false,

        // define a custom xhr function
        // can be used to support XDomainRequest in IE 8 and 9
        //
        // 'url' will be passed the value of 'loadPath'
        // 'options' will be this entire options object
        // 'callback' is a function that takes two parameters, 'data' and 'xhr'.
        //            'data' should be the key:value translation pairs for the
        //            requested language and namespace, or null in case of an error.
        //            'xhr' should be a status object, e.g. { status: 200 }
        // 'data' will be a key:value object used when saving missing translations
        // ajax: function (url, options, callback, data) {},

        // adds parameters to resource URL. 'example.com' -> 'example.com?v=1.3.5'
        // queryStringParams: { v: '1.3.5' }
    },
};
