import { DefaultTheme } from "styled-components";
import { variant } from "styled-system";

import { breakpoints } from "./breakpoints";
import { colors } from "./colors";
import { sizes } from "./sizes";
import { variants } from "./variants";

export { customStylesSystemProps } from "./customStyledSystemProps";
export type { TCustomStyledProps } from "./customStyledSystemProps";

declare module "styled-components" {
    export interface DefaultTheme {
        colors: typeof colors;
        sizes: typeof sizes;
        space: typeof sizes;
        variants: any; // (element: keyof typeof variants) => any;
        breakpoints: typeof breakpoints;
        fontSizes: typeof sizes;
    }
}

export const theme: DefaultTheme = {
    colors,
    fontSizes: sizes,
    space: sizes,
    sizes,
    breakpoints,
    variants: (element) => {
        return variant({
            variants: variants[element],
        });
    },
};
