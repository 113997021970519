import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import i18next from "i18next";
import i18nextXhr from "i18next-xhr-backend";

import { i18nextOptions } from "services/i18n/i18nextOptions.js";
import store from "services/localStore/store";

export class AppApplicationInsights {
    static current;
}

const appInit = (history, language, initFinishedCallback) => {
    // Application Insights init
    if (process.env.REACT_APP_AI_KEY) {
        const reactPlugin = new ReactPlugin();
        AppApplicationInsights.current = new ApplicationInsights({
            config: {
                instrumentationKey: process.env.REACT_APP_AI_KEY,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: { history: history },
                },
            },
        });
        AppApplicationInsights.current.loadAppInsights();
        const state = store.getState();
        if (state.userProfile) AppApplicationInsights.current.setAuthenticatedUserContext(state.userProfile.userName);
    } else {
        console.log("Skipping ApplicationInsights - No key was provided");
    }

    const options = i18nextOptions;
    options.lng = language;
    options.fallbackLng = language; // Avoid loading a second default language
    options.react = { wait: true };
    i18next.use(i18nextXhr).init(options, () => {
        initFinishedCallback();
    });
};

export default appInit;
