import EventEmitter from "events";
import PropTypes from "prop-types";

import { store } from "services/localStore/store";

import { apiroutes } from "../apiroutes/apiroutes";
import { AppApplicationInsights } from "../init/appInit";
import { authFetchAsync } from "./authFetch";
import AuthenticatorOffice from "./authenticator.office";
import AuthenticatorOidc from "./authenticator.oidc";

const LoginEvents = { OnLoggedStateChanged: "onLoggedStateChanged" };

export const AuthenticatorType = { Unknown: 0, Oidc: 1, Office: 2 };

export class Authenticator {
    static authenticatorType = AuthenticatorType.Unknown;
    static mgr;
    static ee = new EventEmitter();
    static lastRenewDate;
    static instance;

    constructor() {
        // console.log("authenticator ctor");

        if (Authenticator.instance) {
            return Authenticator.instance;
        }
        Authenticator.instance = this;
    }

    /**
     * Subscribes authentication events.
     * @param {*} onLoggedStateChanged
     */
    static addListener(onLoggedStateChanged) {
        // Basic removal by name to avoid having to use componentWillUnmount on consumer side
        // Warning: Make sure there are no two listeners with same function name
        Authenticator.ee.listeners(LoginEvents.OnLoggedStateChanged).forEach((element) => {
            // console.log("authenticator has a listener called", element.name);
            if (element.name === onLoggedStateChanged.name) {
                Authenticator.ee.removeListener(LoginEvents.OnLoggedStateChanged, element);
                // console.log("authenticator listener removed", element.name);
            }
        });

        Authenticator.ee.addListener(LoginEvents.OnLoggedStateChanged, onLoggedStateChanged);
    }

    /**
     * Sets the authenticator type.
     * @authenticatorType Oidc or Office.
     */
    static setType(authenticatorType) {
        // console.log("authenticator.setType", authenticatorType);
        Authenticator.authenticatorType = authenticatorType;
        Authenticator.mgr = Authenticator.getAuthenticatorManager();
    }

    /**
     * Gets the authenticated user data.
     * @return authenticated user data.
     */
    static getUserAsync() {
        return Authenticator.mgr.getUserAsync();
    }

    /**
     * Initiates the login process.
     */
    static login() {
        Authenticator.mgr.loginAsync();
        localStorage.setItem("isProductionMode", false);
        localStorage.setItem("demoMode", false);
    }

    /**
     * Initiates the logout process.
     */
    static logout() {
        Authenticator.mgr.logoutAsync().then(() => {
            Authenticator.logoutCompletedAsync();
        });
    }

    /**
     * Call this on the landing redirect page.
     * authenticator.loginCallback(this.props.history);
     */
    static loginCallback(history) {
        Authenticator.mgr
            .loginCallbackAsync()
            .then(function (user) {
                // authenticator.updateUserDetailsFromAD();
                Authenticator.loginCompletedAsync(user).then(() => {
                    if (history) {
                        const appSettings = store.getState().appSettings;
                        // if a current url was set, then we're dealing with a currently logged in user, with an expired session
                        history.push(appSettings.currentUrl ?? "/");
                    }
                });
            })
            .catch(function (e) {
                console.error("loginCallback error", e);
                history.push("/");
            });
    }

    /**
     * Call this on the landing silent redirect page.
     * authenticator.loginSilentCallback();
     */
    static loginSilentCallback() {
        Authenticator.mgr
            .loginSilentCallbackAsync()
            .then(function () {
                console.log("loginSilentCallback ok");
            })
            .catch(function (e) {
                console.error("loginSilentCallback error", e);
            });
    }

    /* Private methods */

    static getAuthenticatorManager() {
        if (Authenticator.authenticatorType === AuthenticatorType.Oidc) return new AuthenticatorOidc();
        else if (Authenticator.authenticatorType === AuthenticatorType.Office) return new AuthenticatorOffice();
        else console.error("Unknown authenticatorType", Authenticator.authenticatorType);
    }

    static logoutCompletedAsync() {
        return new Promise((resolve) => {
            Authenticator.mgr.logoutCallback();

            Authenticator.ee.emit(LoginEvents.OnLoggedStateChanged, null);
            if (AppApplicationInsights.current) {
                AppApplicationInsights.current.clearAuthenticatedUserContext();
            }
            resolve();
        });
    }

    static loginCompletedAsync(user) {
        return new Promise((resolve) => {
            Authenticator.ee.emit(LoginEvents.OnLoggedStateChanged, user);
            if (user && user.profile && AppApplicationInsights.current) {
                AppApplicationInsights.current.setAuthenticatedUserContext(user.profile.unique_name);
            }
            resolve();
        });
    }

    // Ask for an explicit silent signin
    static async loginSilentAsync() {
        Authenticator.mgr
            .loginSilentAsync()
            .then(() => {
                // console.log("loginSilentAsync ok", user)
            })
            .catch((error) => {
                console.error("loginSilentAsync error", error);
                Authenticator.logoutCompletedAsync();
            });
    }

    static async updateUserDetailsFromAD(accessToken) {
        const response = await authFetchAsync(apiroutes.security + "users/activedirectory", "POST", accessToken);

        if (response.ok) {
            return await response.json();
        } else {
            return null;
        }
    }
}

export default Authenticator;

Authenticator.propTypes = {
    onLoggedStateChanged: PropTypes.func,
    addListener: PropTypes.func,
};
