/* tslint:disable */

export const breakpoints = ["319px", "374px", "424px", "767px", "1023px", "1439px", "2559px"];

breakpoints.mobileS = breakpoints[0];
breakpoints.mobileM = breakpoints[1];
breakpoints.mobileL = breakpoints[2];
breakpoints.tablet = breakpoints[3];
breakpoints.laptop = breakpoints[4];
breakpoints.laptopL = breakpoints[5];
breakpoints.fourK = breakpoints[6];
