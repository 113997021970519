import { saveAuthComponentSettingsAsync } from "services/auth/authSettings";

const defaultUserSettings = {
    isBypassCache: false,
    paginationSize: 20,
    pivotMode: false,
    openInNewTab: true,
    isProductionMode: false,
    demoMode: false,
};

export default (state = defaultUserSettings, action) => {
    switch (action.type) {
        case "SET_USER_SETTINGS":
            return {
                ...action.value,
                componentSettings: JSON.parse(action.value.componentSettings),
            };
        case "RESET_USER_SETTINGS":
            return defaultUserSettings;
        case "SET_CACHE_SETTINGS":
            return {
                ...state,
                isBypassCache: action.value,
            };
        case "SET_PRODUCTION_MODE_SETTINGS":
            return {
                ...state,
                isProductionMode: action.value,
            };
        case "SET_OPEN_IN_NEW_TAB_SETTINGS":
            return {
                ...state,
                openInNewTab: action.value,
            };
        case "SET_DEMO_MODE_SETTINGS":
            return {
                ...state,
                demoMode: action.value,
            };
        case "SET_PIVOTMODE_SETTINGS":
            return {
                ...state,
                pivotMode: action.value,
            };
        case "SET_PAGINATION_SETTINGS":
            return {
                ...state,
                paginationSize: action.value,
            };
        case "SET_COMPONENT_SETTINGS":
            return {
                ...state,
                componentSettings: action.value,
            };
        case "RESET_COMPONENT_SETTINGS":
            return {
                ...state,
                componentSettings: [],
            };
        case "UPDATE_COMPONENT_SETTINGS":
            return {
                ...state,
                componentSettings: updateComponentSettings(state.componentSettings || [], action),
            };
        default:
            return state;
    }
};

const updateComponentSettings = (componentSettings, setting) => {
    // store component setting in db user settings
    saveAuthComponentSettingsAsync({
        Id: setting.componentId,
        TypeId: setting.componentTypeId,
        Settings: setting.value,
    });

    if (
        !componentSettings.find(
            (cSetting) => cSetting.Id === setting.componentId && cSetting.TypeId === setting.componentTypeId
        )
    ) {
        // If component setting doesn't exist, add a new one
        componentSettings.push({ Id: setting.componentId, TypeId: setting.componentTypeId, Settings: setting.value });

        return componentSettings;
    } else {
        // Else update component settings
        return componentSettings.map((cSetting) => {
            if (cSetting.Id === setting.componentId && cSetting.TypeId === setting.componentTypeId) {
                return { ...cSetting, Settings: setting.value };
            } else return cSetting;
        });
    }
};

export const getComponentSetting = (componentSettings, typeId, id) => {
    return componentSettings
        ? componentSettings.find((settings) => settings.TypeId === typeId && settings.Id === id)
        : null;
};
