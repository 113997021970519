import dayjs from "dayjs";
import i18next from "i18next";
import moment from "moment";

/* Date formatters */

export const DateFormatEnum = {
    Hour: "HH:mm",
    DateYearMonth: "YYYY-MM",
    Date: "YYYY-MM-DD",
    DateTime: "MMM-DD-YYYY HH:mm",
    DateFullTime: "MMM-DD-YYYY HH:mm:ss",
};

/**
 * Pad - Pad Month and Date with 0 if leq 9
 */
function Pad(n) {
    return n > 9 ? n : "0" + n;
}

/**
 * formatUTCDateIgnoreTimezone - Return UTC date in YYYY-MM-DD format
 * @param {string} date
 */
export const formatUTCDateIgnoreTimezone = function (date) {
    if (!date) return date;
    if (typeof date === "string" && (!date || date?.startsWith("0"))) return null;

    return moment.utc(date).format("YYYY-MM-DD");
};

/**
 * formatUTCDateIgnoreTimezone - Return UTC date in YYYY-MM-DD format
 * @param {Date} date
 */
export const formatDateIgnoreTimezone = function (date) {
    if (!date) return date;

    return date.getUTCFullYear() + "-" + Pad(date.getUTCMonth() + 1) + "-" + Pad(date.getUTCDate());
};

/**
 * Converts an ISO date string or date object to a local date string.
 * @param {string} date Input date string.
 * @param {string} format Format to output - default is YYYY-MM-DD.
 * @param {boolean} duseFriendlyNamesate Return friendly names if match.
 */
export const formatUtcDate = function (date, format = DateFormatEnum.Date, useFriendlyNames = false) {
    if (!date) return date;

    if (date instanceof Date) return dayjs(date).format(format);

    if (date?.startsWith("0")) return null;

    if (useFriendlyNames && new Date().toDateString() === new Date(date).toDateString())
        return i18next.t("common:date.today");

    // stop using utc format
    // if (!date.endsWith("Z")) date = date + "Z";

    return dayjs(new Date(date)).format(format);
};

/**
 * Parse a date string into a date object.
 * @param {date} date Input date string.
 */
export const parseUtcDate = function (date) {
    if (!date) return date;
    if (date instanceof Date) return date;

    if (!date.endsWith("Z")) date = date + "Z";
    return new Date(date);
};

/**
 * parseCalendarUtcDate (display date in the calendar control)
 * Parse a date string into a date object.
 * @param {date} date Input date string.
 */
export const parseCalendarUtcDate = function (date) {
    if (!date) return date;
    if (date) {
        const newDate = new Date(date);
        return new Date(newDate.toUTCString());
    }
};

/**
 * Calculate the number of weekdays between two dates.
 */
export const workingDaysBetweenDates = function (d0, d1, holidays = []) {
    const startDate = parseUtcDate(d0);
    const endDate = parseUtcDate(d1);

    // Validate input
    if (endDate < startDate) {
        return 0;
    }

    // Calculate days between dates
    const millisecondsPerDay = 86400 * 1000; // Day in milliseconds
    // startDate.setHours(0, 0, 0, 1);  // Start just after midnight
    // endDate.setHours(23, 59, 59, 999);  // End just before midnight

    const diff = endDate - startDate; // Milliseconds between datetime objects
    let days = Math.ceil(diff / millisecondsPerDay);

    // Subtract two weekend days for every week in between
    const weeks = Math.floor(days / 7);
    days -= weeks * 2;

    // Handle special cases
    const startDay = startDate.getDay();
    const endDay = endDate.getDay();

    // Remove weekend not previously removed.
    if (startDay - endDay > 1) {
        days -= 2;
    }
    // Remove start day if span starts on Sunday but ends before Saturday
    if (startDay === 0 && endDay !== 6) {
        days--;
    }
    // Remove end day if span ends on Saturday but starts after Sunday
    if (endDay === 6 && startDay !== 0) {
        days--;
    }
    // Remove holidays
    holidays.forEach((day) => {
        if (day >= d0 && day <= d1) {
            /* If it is not saturday (6) or sunday (0), substract it */
            if (parseUtcDate(day).getDay() % 6 !== 0) {
                days--;
            }
        }
    });
    return days;
};

/* Fixed point number formatters */

export const acceptDecimal = function () {
    return new RegExp(`[\\d${i18next.t("common:settings.decimalseparator")}-]+`, "g");
};
export const cleanDecimal = function (string) {
    return (string.toString().match(acceptDecimal()) || []).join("");
};
export const formatDecimal = function (value, digits) {
    return value === undefined || value === null || value === ""
        ? value
        : Number.parseFloat(value).toLocaleString(i18next.t("common:settings.localenumber"), {
              minimumFractionDigits: digits,
              maximumFractionDigits: digits,
          });
};
export const parseDecimal = function (string) {
    return string === undefined || string === null || string === ""
        ? string
        : string === "-"
        ? null
        : cleanDecimal(string).replace(i18next.t("common:settings.decimalseparator"), ".");
};

/* Bytes size formatters */

export const formatBytes = function (bytes, decimals = 0) {
    if (bytes === 0) return "0 B";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

/**
 * style the number with certain digits and optionally a currency sign
 * @param {object} value - the data input
 * @param {number} digit - how many digits to keep
 * @param {string?} currency - "EUR", "USD", "GBP" or null
 * @returns -styled data
 */
export const numberFormatter = (value, currency = null, digit = 2, maxDigit = 2) => {
    if (isNaN(value) || value === null || value === undefined) {
        return "";
    }
    const options = currency
        ? {
              style: "currency",
              currency: currency,
              minimumFractionDigits: digit,
              maximumFractionDigits: maxDigit,
          }
        : {
              style: "decimal",
              minimumFractionDigits: digit,
              maximumFractionDigits: maxDigit,
          };
    return new Intl.NumberFormat("en-US", options).format(value);
};

export const acctNumFormatter = (value) => {
    if (value === undefined || value === null) {
        return "";
    }

    if (value.split(" ").length > 1) {
        return value.split(" ")?.join("-");
    } else {
        return value.match(/.{1,4}/g)?.join("-");
    }
};

export const yearFormatter = (value) => {
    const date = new Date(value);
    return `${date.getFullYear()}`;
};
