export const toggleMenuState = () => {
    return { type: "TOGGLE_MENU" };
};

export const togglePinnedState = () => {
    return { type: "TOGGLE_PIN" };
};

export const hideMenu = () => {
    return { type: "HIDE_MENU" };
};

export const setTopbarActiveItem = (menuItem) => {
    return {
        type: "TOPBAR_ACTIVE_ITEM",
        value: menuItem,
    };
};

export const setTopbarActiveItemId = (menuItem) => {
    return {
        type: "TOPBAR_ACTIVE_ITEM_ID",
        value: menuItem,
    };
};

export const setUserTheme = (theme) => {
    return {
        type: "SET_THEME",
        value: theme,
    };
};

export const setAppConfigurations = (configs) => {
    return {
        type: "SET_CONFIGURATIONS",
        value: configs,
    };
};

export const setCurrentUrl = (url) => {
    return {
        type: "SET_URL",
        value: url,
    };
};

export const setBreadcrumbCache = (breadcrumb) => {
    return {
        type: "SET_BREADCRUMBCACHE",
        value: breadcrumb,
    };
};

export const addToBreadcrumbCache = (breadcrumb) => {
    return {
        type: "ADD_BREADCRUMBCACHE",
        value: breadcrumb,
    };
};

export const setLockScreen = (lockScreen) => {
    return {
        type: "SET_LOCK_SCREEN",
        value: lockScreen,
    };
};
