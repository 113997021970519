const defaultInstanceMenus = {};

export default (state = defaultInstanceMenus, action) => {
    switch (action.type) {
        case "SET_INSTANCE_MENUS": {
            // action is expected to be:
            // { type: <string>,
            //   entry: {
            //     id: "<Source>|<Source Id>",
            //     threadCount: <int>,
            //     conversationsVisible: <bool>,
            //     historyVisible: <bool>,
            //     valStatusVisible: <bool>,
            //     highlightId: <int>
            //   }
            // }
            const previous = state[action.entry.id];
            state[action.entry.id] = previous ? { ...previous, ...action.entry } : action.entry;
            return state;
        }
        case "RESET_INSTANCE_MENUS":
            return defaultInstanceMenus;
        case "RESET_INSTANCE_MENUS_HIGHLIGHT":
            if (state)
                Object.keys(state).forEach((element) => {
                    state[element].highlightId = undefined;
                });
            return state;
        default:
            return state;
    }
};
