export const colors = {
    primary: "#0D8FB8",
    secondary: "#94C2CF",
    accent: "#ff0070",
    success: "#32CD32",
    warning: "#FF6347",
    danger: "#ff0000",
    info: "#0D8FB8",
    disabled: "#d3d3d3",
    lite: "#d3d3d3",
    dark: "#000000",
    white: "#ffffff",
    black: "#000000",
    text: "#333333",
    transparent: "transparent",
};
