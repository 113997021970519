import { combineReducers, createStore } from "redux";

import appMessageReducer from "./reducers/appMessage.js";
import appSettingsReducer from "./reducers/appSettings.js";
import eventsReducer from "./reducers/events.js";
import fmsSettings from "./reducers/fmsSettings.js";
import instanceMenusReducer from "./reducers/instanceMenus.js";
import refreshData from "./reducers/refreshData";
import tasksBarReducer from "./reducers/tasksBar.js";
import tempProfile from "./reducers/tempProfile.js";
import tenantSettingsReducer from "./reducers/tenantSettings.js";
import userContext from "./reducers/userContext.ts";
import userFavouritesReducer from "./reducers/userFavourites.js";
import userMenusReducer from "./reducers/userMenus.js";
import userProfileReducer from "./reducers/userProfile.js";
import userRightsReducer from "./reducers/userRights.js";
import userSettingsReducer from "./reducers/userSettings.js";

export const store = createStore(
    combineReducers({
        events: eventsReducer,
        appMessages: appMessageReducer,
        appSettings: appSettingsReducer,
        fmsSettings: fmsSettings,
        userMenus: userMenusReducer,
        userProfile: userProfileReducer,
        userRights: userRightsReducer,
        userSettings: userSettingsReducer,
        userFavourites: userFavouritesReducer,
        tenantSettings: tenantSettingsReducer,
        instanceMenus: instanceMenusReducer,
        tasksBarState: tasksBarReducer,
        tempProfile: tempProfile,
        refreshData: refreshData,
        userContext: userContext,
    })
);

export default store;
