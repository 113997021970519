const host = window.location.protocol + "//" + window.location.host;

export const apiroutes = {
    api: `${host}/api/`,
    assets: `${host}/api/assets/`,
    assetownerships: `${host}/api/GXAssets/assetownership/`,
    batchprocesses: `${host}/api/batchprocesses/`,
    communications: `${host}/api/communications/`,
    contactbook: `${host}/api/contactbook/`,
    contractualobligationtype: `${host}/api/contractualobligationtype/`,
    core: `${host}/api/core/`,
    customers: `${host}/api/customers/`,
    documentation: `${host}/api/documentation/`,
    debts: `${host}/api/debts/`,
    duediligencies: `${host}/api/duediligencies/`,
    finance: `${host}/api/finance/`,
    bankaccounts: `${host}/api/finance/bankaccounts/`,
    legal: `${host}/api/legal/`,
    portfolios: `${host}/api/dealportfolios/`,
    reporting: `${host}/api/reporting/`,
    requests: `${host}/api/requests/`,
    security: `${host}/api/security/`,
    agreements: `${host}/api/agreements/`,
    home: `${host}/api/home/`,
    deals: `${host}/api/deals/`,
    banking: `${host}/api/banking/`,
    fund: `${host}/api/fund/`,
    marketdata: `${host}/api/marketdata/`,
};
