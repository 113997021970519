const specialSizes = {
    full: "100%",
    halfQuarter: "12.5%",
    quarter: "25%",
    third: "33.333333%",
    half: "50%",
    twoThirds: "66.666667%",
    threeQuarters: "75%",
    auto: "auto",
    initial: "initial",
    inherit: "inherit",
    none: "none",
    unset: "unset",
    min: "min-content",
    max: "max-content",
    fit: "fit-content",
    screen: "100vw",
    screenMin: "min(100vw, 100%)",
    screenMax: "max(100vw, 100%)",
    screenFit: "fit(100vw, 100%)",
    screenQuarter: "25vw",
    screenThird: "33.333333vw",
    screenHalf: "50vw",
    screenTwoThirds: "66.666667vw",
    screenThreeQuarters: "75vw",
    screenHalfQuarter: "12.5vw",
    screenHalfThird: "16.666667vw",
};

export const sizes = {
    xs: "10px",
    sm: "20px",
    md: "40px",
    lg: "80px",
    xl: "160px",
    xxl: "320px",
    xxxl: "640px",
    big: "1280px",
    ...specialSizes,
};
