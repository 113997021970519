export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const SHOW_MULTIPLE_MESSAGE = "SHOW_MULTIPLE_MESSAGE";

export const show = (message, severity, summary) => ({
    type: SHOW_MESSAGE,
    message: { severity: severity, summary: summary, detail: message },
});

export const showMultiple = (message) => ({
    type: SHOW_MULTIPLE_MESSAGE,
    message: [...message],
});
