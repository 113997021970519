import React from "react";

import classNames from "classnames";

import styles from "./Loading.module.scss";

function Loading({ text = null, showUserAgentInfo = false }) {
    return (
        <div className="loader-screen">
            <div className="loader">
                <i className={classNames("fad", "fa-spinner-third", "fa-pulse", "fa-5x", "fa-fw", styles.icon)} />
            </div>
            {text && <div className="loader loader-text">{text}</div>}
            {showUserAgentInfo && <div id="userAgentInfo">{window.navigator.userAgent}</div>}
        </div>
    );
}

export default Loading;
