const defaultRefreshState = {
    refreshState: false,
};

export default (state = defaultRefreshState, action) => {
    switch (action.type) {
        case "REFRESH_DATA":
            return {
                ...state,
                refreshState: !state.refreshState,
            };
        default:
            return state;
    }
};
